@import 'variables';

body {
  font-family: $primary-font;
  margin: 0;
  color: $primary-color;
}

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
//     monospace;
// }
