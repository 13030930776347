@import '../../styles/index.scss';

.design-project-page {
  padding: 83px 200px 225px 200px;
  .design-project {
    margin-top: 100px;
  }
  .design-project:nth-of-type(1) {
    margin-top: 50px;
  }
  h1 {
    font-family: $primary-font;
    font-weight: 600;
    color: $terra-cotta;
    letter-spacing: 0.75px;
    margin: 0;
  }
  h6 {
    font-weight: 300;
  }
  h3 {
    font-family: $primary-font;
    font-weight: 500;
    font-size: 22px;
    padding-bottom: 15px;
  }
  p {
    font-weight: 200;
    padding: 0;
    margin-top: 35px;
    max-width: 80%;
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 10px;
  grid-auto-flow: dense;
  justify-items: center;
  align-items: start;
}
.w-1 {
  grid-column: span 1;
}
.w-2 {
  grid-column: span 2;
}
.w-3 {
  grid-column: span 3;
}
.w-4 {
  grid-column: span 4;
}
.w-7 {
  grid-column: span 7;
}
.h-1 {
  grid-row: span 1;
}
.h-2 {
  grid-row: span 2;
}
.h-3 {
  grid-row: span 3;
}
.h-7 {
  grid-row: span 7;
}
.design-item {
  width: 100%;
  height: 100%;
  position: relative;
}
.design-item .image {
  width: 100%;
  height: 100%;
  overflow: hidden;
  &.border {
    border: 1.5px solid $light-gray;
  }
}
.design-item .image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media screen and (max-width: 1200px) {
  .design-project-page {
    padding: 83px 100px 225px 100px;
  }
}
@media screen and (max-width: 975px) {
  p {
    max-width: 100% !important;
  }
}

@media screen and (max-width: 775px) {
  .design-project-page {
    padding: 83px 45px 225px 45px !important;
  }
}
@media screen and (max-width: 465px) {
  .design-project-page {
    padding: 83px 20px 225px 20px !important;
  }
}

// DARK MODE

#dark {
  .design-project-page {
    background-color: $dm-primary-color;
    h6 {
      color: $golden;
    }
    p {
      color: $white;
    }
  }
}