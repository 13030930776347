$primary-color: #5E5A53;
$dark-green: #CCD5B0;
$light-green: #E9EDCB;
$beige: #FEFAE1;
$light-gray: #e9e9e9;
$white: white;
$primary-font: 'Montserrat', 'sans-serif';
$secondary-font: 'Abril Fatface';
$h1: 38px;
$h3: 32px;
$h5: 28px;
$h6: 18px;
$terra-cotta: #d97a56;
$golden: #EEB237;
$morning-blue:#86AF9A;

// DARK MODE
$dm-primary-color: #292828;