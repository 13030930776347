@import '../../styles/index.scss';

.navbar {
  padding: 25px 95px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  font-family: 'Montserrat', sans-serif !important;
  align-items: center;
}

.brand {
  text-decoration: none !important;
  font-family: $primary-font;
  font-size: 26px;
  font-weight: 500 !important;
  color: $primary-color;
}

.nav {
  align-content: center;
  .navbar-item.active {
      border-bottom: solid 2px $golden;
  }
}

.navbar-item {
  margin-left: 45px;
  font-size: 15px;
  font-weight: 400;
  text-decoration: none !important;
  color: $primary-color;
}

@media screen and (min-width: 961px) {
  .navbar-item:hover {
    box-shadow: inset 0 -8px 0 $golden;
  }
}

.brand:hover {
  box-shadow: inset 0 -8px 0 $golden;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .nav {
    display: none;
  }
  .nav-active {
    z-index: 2;
    display: flex;
    flex-direction: column;
    height: 75px;
    position: absolute;
    top: 22px;
    right: 0;
    background-color: white;
    transition: all 0.5s ease;
    margin-right: 75px;
    text-align: right;
    padding: 33px 20px 25px 30px;
    border: 1px solid $light-gray;
    box-shadow: 3px 3px 0 rgba(224, 224, 224, 0.136);
    border-radius: 5px;
    a:nth-child(even) {
      border-top: 0.5px solid $light-gray;
      border-bottom: 0.5px solid $light-gray;
    }
  }
  .navbar-item {
    margin: 0;
    padding: 5px;
    &.active {
      color: $dark-green;
    }
  }

  .navbar-item:hover {
    border-right: 5px solid $golden;
  }

  .menu-icon {
    display: block;
    top: 0;
    right: 0;
    z-index: 3;
  }
}

@media screen and (max-width: 660px) {
  .navbar {
    padding: 15px calc(25px + 3vw);
    .brand {
      font-size: 22px;
    }
  }

  .nav-active {
    margin-right: 20px;
    top: 10px;
  }
}

// DARK MODE
#dark {
  .brand, .navbar-item, .menu-icon {
    color: $white !important;
  }
  .nav-active {
    background-color: $dm-primary-color;
    border-color: $morning-blue;
    .navbar-item {
      color: $terra-cotta !important;
      border-color: $golden;
    }
  }

}