@import '../../styles/index.scss';

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}
.toggle-switch input[type='checkbox'] {
  display: none;
}
.toggle-switch .switch {
  position: absolute;
  cursor: pointer;
  border-radius: 25px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: background-color 0.2s ease;
}
.toggle-switch .switch::before {
  position: absolute;
  content: '';
  left: 2px;
  top: 2px;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  transition: transform 0.3s ease;
}
.toggle-switch input[type="checkbox"]:checked + .switch::before {
    transform: translateX(25px);
  }

// LIGHT MODE
#light {
  .toggle-switch .switch {
    background-color: $morning-blue;
  }
  .toggle-switch .switch::before {
    background-color: $white;
  }
}

// DARK MODE
#dark {
  .toggle-switch .switch {
    background-color: $golden;
  }
  .toggle-switch .switch::before {
    background-color: $dm-primary-color;
  }
}
