@import '../../styles/index.scss';

.development-project-page {
  padding: 83px 200px 225px 200px;
  h3 {
    font-family: $secondary-font;
    font-size: $h3;
    font-weight: 100;
    margin: 0;
    letter-spacing: 0.75px;
  }
  h6 {
    font-size: $h6;
    font-weight: 300;
    margin: 0;
  }
  p {
    font-weight: 200;
    padding: 0;
    margin-top: 35px;
    max-width: 80%;
  }
  h4 {
    margin-top: 40px;
  }
  li {
    padding-bottom: 2px;
  }
}

@media screen and (max-width: 1200px) {
  .development-project-page {
    padding: 83px 100px 225px 100px;
  }
}
@media screen and (max-width: 775px) {
  p {
    max-width: 100% !important;
  }
}
@media screen and (max-width: 575px) {
  .development-project-page {
    padding: 83px 25px 225px 25px;
  }
}
