@import '../../styles/index.scss';

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;

  .socials {
    display: flex !important;
    margin-top: 35px;
    justify-content: center;
    margin: auto;
    background-color: $morning-blue;
    width: 175px;
    border-radius: 25px;
  }
  .icon-link {
    border-radius: 100%;
    height: 40px;
    width: 40px;
    display: flex;
  }
  .icon-link:hover {
    background-color: $white;
    .icon {
      color: $morning-blue !important;
    }
  }
  .icon {
    margin: auto;
    height: 30px;
    color: $white;
    &.fa-envelope,
    &.fa-linkedin {
      height: 24px;
    }
  }
  .copyright {
    text-align: center;
    padding: 16px 0px;
    margin: 0;
  }
}

@media screen and (max-width: 660px) {
  .icon-link {
    margin: 0px 0.2rem !important;
    height: 35px !important;
    width: 35px !important;
  }
  .icon {
    height: 25px !important;
  }
  .copyright {
    text-align: center;
    padding: 7px 0px 15px !important;
    margin: 0;
  }
}

// DARK MODE
#dark {
  .socials {
    background: none;
    .icon {
      color: $morning-blue;
    }
  }
  .copyright {
    color: $morning-blue;
  }

}