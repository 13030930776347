@import '../../styles/index.scss';

.home-page {
  .navbar {
    background-color: transparent;
  }
  .footer {
    .socials {
      background-color: transparent;
    }
    .icon-link,
    .icon-link:hover {
      border: none;
    }
    .icon-link:hover {
      background-color: $white;
      .icon {
        color: $morning-blue !important;
      }
    }
    .icon,
    .copyright {
      color: $white;
    }
  }
}

.banner {
  background: url(../../images/HomePageGraphics.png);
  background-size: cover;
  height: calc(100vh - 75px);
  text-align: center;
  margin-right: 25px;
  .headline-container {
  padding: 170px 40% 0px 7%;
  }
  h1 {
    font-size: 48px;
    font-family: $primary-font;
    font-weight: 500;
    letter-spacing: -2px;
    margin: 15px 0px 0px 0px;
    color: $terra-cotta;
  }
  h2 {
    font-size: 32px;
    font-family: $primary-font;
    font-weight: 300;
    margin: -3px;
    padding-bottom: 15px;
    color: $golden;
  }

  p {
    font-weight: lighter;
    font-size: 24px;
    padding-bottom: 50px;
    margin: 0;
  }
  .button-container {
    height: 33px;
    .button {
      text-decoration: none;
      background-color: $terra-cotta;
      border: none;
      margin: 0px 5px;
      padding: 10px 15px;
      border-radius: 25px;
      color: $white;
      font-family: $primary-font;
      font-weight: 400;
      font-size: 18px;
    }
    .button:hover {
      background-color: $golden;
    }
  }
  .switch-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    .icon-sun {
      height: 60px;
      margin-right: 15px;
    }
    .icon-moon {
      height: 42px;
      margin-left: 12px;
    }
  }
}

.skills {
  background-color: $morning-blue;
  padding: 100px 75px 175px 75px;
  display: flex;
  justify-content: center;
  align-items: stretch !important;
  .skills-card {
    font-family: $primary-font;
    max-width: 350px;
    min-width: 320px;
    background-color: $white;
    border: $golden 3px solid;
    &:nth-child(2),
    &:nth-child(3) {
      margin-left: -2px;
    }
    &:first-child {
      border-top-left-radius: 25px;
      border-bottom-left-radius: 25px;
    }
    &:last-child {
      border-top-right-radius: 25px;
      border-bottom-right-radius: 25px;
    }

    .skills-card-header {
      margin: 80px 15px 15px 15px;
      display: flex !important;
      .icon {
        height: 75px;
        color: $golden;
      }
    }

    h5 {
      width: 300px;
      margin: 0;
      font-size: $h5;
      font-weight: 600;
      letter-spacing: 0.25px;
      color: $terra-cotta;
      text-align: left;
      padding-left: 10px;
      align-self: center;
    }
    p {
      color: $primary-color;
      font-weight: 300;
      padding: 5px 25px 50px 35px;
      max-width: 300px;
      text-align: left;
    }
  }
}

// RESPONSIVE
@media screen and (max-width: 450px) {
  .banner {
    height: calc(100vh - 160px);
    .headline-container{
      padding: 60px 35px 0px 35px
    }
    h1 {
      font-size: 38px;
    }
    h2 {
      font-size: 24px;
    }
    p {
      font-size: 20px;
      padding-bottom: 65px;
    }
    .button {
      font-size: 14px !important;
      padding: 7px 12px !important;
    }
  }
}

@media screen and (max-width: 960px) {
  .banner {
    margin-top: 50px !important;
  }
  .skills {
    flex-wrap: wrap;
  }
  .skills-card {
    text-align: center;
    height: fit-content;
    width: fit-content;
    max-width: 30% !important;
    min-width: 215px;
    &:nth-child(2) {
      border-top-right-radius: 25px;
      border-bottom-right-radius: 25px;
    }
    &:last-child {
      border-top-right-radius: 0px !important;
      border-bottom-left-radius: 25px;
      margin-top: -2px;
    }
  }
}

@media screen and (max-width: 800px) {
  .skills {
    flex-direction: column;
    align-content: center;
    .skills-card {
      &:first-child {
        border-top-right-radius: 25px;
        border-bottom-left-radius: 0px;
      }
      &:nth-child(2) {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        margin-top: -2px;
      }
      &:nth-child(2),
      &:nth-child(3) {
        margin-left: 0;
      }
    }
  }
}

@media screen and (max-width: 660px) {
  .home-page {
    .banner {
      padding-top: 0px !important;
      .headline-container {
        margin-top: 0px !important;
        padding-top: 0px !important;
      }
    }
  }
  .skills-card-header {
    .icon {
      height: 75px !important;
    }
  }
}

#light {
  @media screen and (max-width: 1300px) {
    .banner {
      height: calc(100vh - 75px);
      .headline-container {
        padding: 150px 40% 0px 7%;
        }
    }
  }

  @media screen and (max-width: 1150px) {
    .banner {
      margin-right: 0px;
      height: calc(100vh - 75px);
      .headline-container {
        padding: 110px 40% 0px 7%
      }
    }
  }

  @media screen and (max-width: 970px) {
    .banner {
      background: none;
      .headline-container {
        padding: 70px 75px 0px 75px;
      }
    }
  }

  @media screen and (max-width: 660px) {
    .home-page {
      .banner {
        padding-top: 0px !important;
        .headline-container {
          margin-top: 0px !important;
          padding-top: 0px !important;
        }
      }
    }
    .skills-card-header {
      .icon {
        height: 75px !important;
      }
    }
  }
  @media screen and (max-width: 619px) {
    .banner {
      .headline-container {
        padding: 60px 25px 0px 25px !important;
      }
    }
  }
  @media screen and (max-width: 539px) {
    .banner {
      height: calc(100vh - 160px);
      .headline-container{
        padding: 50px 15px 0px 15px !important;
      }
      h1 {
        font-size: 38px;
      }
      h2 {
        font-size: 24px;
      }
      p {
        font-size: 20px;
        padding-bottom: 65px;
      }
      .button {
        font-size: 16px;
        padding: 10px 15px !important;
      }
      .icon-sun {
        height: 48px !important;
      }
      .icon-moon {
        height: 35px !important;
      }
    }
  }
  @media screen and (max-width: 348px) {
    .banner {
      padding: 30px 10px 0px 10px;
    }
  }
}




// DARK MODE
#dark {
  .home-page {
    background-color: $dm-primary-color;
    .banner {
      .headline-container {
        padding: 170px 42% 0px 7% !important;
      }
      h1 {
        color: $terra-cotta;
        background-color: $dm-primary-color;
      }
      h2 {
        color: $golden;
        background-color: $dm-primary-color;
      }
      p {
        color: $white;
        background-color: $dm-primary-color;
      }
      .switch-container {
        background-color: $dm-primary-color;
        margin: auto;
        width: fit-content;
        margin-top: 50px;
      }
    }
  }

  .skills {
    background-color: $dm-primary-color;
    border-top: 10px solid $golden;
    .skills-card {
      background-color: $dm-primary-color;
      p{
        color: $white;
      }
    }
  }

  // RESPONSIVE -- DARK MODE
  @media screen and (max-width: 1000px) {
    .home-page {
      .banner {
        background: none !important;
        .headline-container {
          padding: 70px 75px 0px 75px !important;
        }
      }
    }
  }
  @media screen and (max-width: 960px) {
    .banner {
      margin-top: 50px !important;
    }
  }
  @media screen and (max-width: 619px) {
    .home-page {
      .banner {
        .headline-container {
          padding: 60px 10px 0px 10px !important;
        }
      }
    }
  }
  @media screen and (max-width: 539px) {
    .home-page {
      .banner {
        height: calc(100vh - 70px);
        .headline-container{
          padding: 50px 15px 0px 15px !important;
        }
        h1 {
          font-size: 38px;
        }
        h2 {
          font-size: 24px;
        }
        p {
          font-size: 20px;
          padding-bottom: 65px;
        }
        .button {
          font-size: 16px;
          padding: 10px 15px !important;
        }
        .icon-sun {
          height: 48px !important;
        }
        .icon-moon {
          height: 35px !important;
        }
      }
    }
  }
}
