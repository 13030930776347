@import '../../styles/index.scss';
.projects {
  button {
    border-radius: 7px;
  }
}

.project-content {
  padding: 30px 50px;
  a {
    text-decoration: none;
  }
}

h3 {
  font-family: $primary-font;
  font-size: $h1;
  font-weight: 600;
  margin: 0;
  color: $terra-cotta;
}

h6 {
  font-size: $h5;
  font-weight: 300;
  margin: 0;
}

.project-tech {
  margin-top: 15px;
}

.tech-button {
    background-color: $golden;
    border: none;
    margin-right: 5px;
    margin-bottom: 7px;
    padding: 8px 20px;
    border-radius: 25px !important;
    color: $white;
    font-family: $primary-font;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.5px;
}

.design-page-container {
  display: flex;
  justify-content: space-between;
  .project-button {
    align-self: end;
    margin-bottom: 30px;
  }
  .arrow-icon {
    color: $primary-color;
    height: 20px;
  }
  .arrow-icon:hover {
    color: $golden;
  }
}

.project-date {
  p {
    color: $morning-blue;
    margin-top: 5px;
    font-weight: 300;
  }
}

.project-description {
  color: $primary-color;
  p {
    font-weight: 300;
  }
  ul {
    padding-left: 15px !important;
  }
  li {
    list-style-type: disc;
    font-family: $primary-font;
    color: $primary-color;
    font-weight: 300;
    margin-bottom: 5px;
    padding: 0px !important;
    &::marker {
    color: $primary-color;
    }
  }
}

@media screen and (max-width: 960px) {
  .projects {
    padding: 75px 75px 200px 75px;
  }
}

@media screen and (max-width: 575px) {
  .projects {
    padding: 75px 45px 200px 45px;
  }
}

@media screen and (max-width: 465px) {
  .projects {
    padding: 75px 15px 200px 15px;
  }
  .project-content {
    padding: 30px 35px;
  }
}

// DARK MODE
#dark {
  .navbar {
    background-color: $dm-primary-color;
  }
  .page {
    background-color: $dm-primary-color;
    h6 {
      color: $golden;
    }
    li, li::marker {
      color: $white;
    }
    .tech-button {
      color: $dm-primary-color;
    }
    .project-button {
      .arrow-icon {
        color: $morning-blue;
        &:hover {
          color: $white;
        }
      }
    }
  }
}
